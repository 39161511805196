/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/jquery-validation@1.19.0/dist/jquery.validate.min.js
 * - /npm/popper.js@1.14.7/dist/umd/popper.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/@fancyapps/fancybox@3.5.6/dist/jquery.fancybox.min.js
 * - /npm/mediaelement@4.2.9/build/mediaelement-and-player.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
